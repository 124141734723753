;(function($){
//------------------------------------------------------------------------------------------------------------------------
    // (1) Inicializacio

    // Document ready
    $(function(){
        // SVG betoltese es fallback
        Page.loadSVG();

        // Projektek
        $('#projects').each(function(i, target){
            Page.setIsotope($(target));
        });

        // Slideshow
        $(document).on('click', '.project', function(e){
            e.preventDefault();
            Page.setGallery($(this));
        });

        // Menu es tartalom kinyitasa
        $(document).on('click', 'a[data-section]', function(e){
            e.preventDefault();
            Page.openContent($(this));
        });

        // Menu es tartalom bezarasa
        $(document).on('click', 'section .icon.icon-close', function(e){
            e.preventDefault();
            Page.closeContent($(this));
        });
    });

//------------------------------------------------------------------------------------------------------------------------
    // (2) Oldal betoltese

    // Oldal betoltese
    $(window).on('load', function(e){
        // Slide-in effekt
        Page.projectSlideIn();
    });

//------------------------------------------------------------------------------------------------------------------------
    // (3) Oldal gorgetese

    // Oldal gorgetese
    $(window).on('scroll', function(e){
        // Slide-in effekt
        Page.projectSlideIn();
    });

//------------------------------------------------------------------------------------------------------------------------
    // (4) Oldal atmeretezese vagy nezet valtasa

    // Oldal atmeretezese vagy nezet valtasa
    $(window).on('orientationchange resize', function(e){
        // Slide-in effekt
        Page.projectSlideIn();
    });

//------------------------------------------------------------------------------------------------------------------------
    // (5) Lathatosag

    var $w = $(window);
    $.fn.visible = function(){
        if(this.length < 1) return;
        var $t          = this.length > 1 ? this.eq(0) : this,
            t           = $t.get(0),
            vpHeight    = $w.height(),
            viewTop     = $w.scrollTop(),
            viewBottom  = viewTop + vpHeight,
            offset      = $t.offset(),
            _top        = offset.top,
            _bottom     = _top + $t.height();
        return _top <= viewBottom;
    };

//------------------------------------------------------------------------------------------------------------------------
})(jQuery);
