//------------------------------------------------------------------------------------------------------------------------
    // (1) Oldal konstruktor

// Oldal konstruktor
var Page = (function($){
    return {

//------------------------------------------------------------------------------------------------------------------------
    // (2) Globalis valtozok

        grid: null,

        // isotope
        isotope: {
            itemSelector: '.project',
            filter: '*',
            getSortData: {
                order: '[data-order] parseInt',
                name: 'h2'
            },
            sortBy: ['order', 'name'],
            transitionDuration: '500ms'
        },

        // lightGallery
        lightGallery: {
            selector: '.project',
            autoplay: false,
            autoplayControls: false,
            download: false,
            fullScreen: false,
            share: false,
            thumbnail: false,
            zoom: false,
            youtubePlayerParams: {
                color: '#00c0f3',
                modestbranding: 1,
                rel: 0,
                showinfo: 0
            },
            vimeoPlayerParams: {
                color: '#00c0f3',
                byline: 0,
                portrait: 0
            }
        },

//------------------------------------------------------------------------------------------------------------------------
    // (3) isotope

        // isotope betoltese
        setIsotope: function($target, params){
            // Beallitasok felulbiralasa
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, Page.isotope, params);

            // isotope inicializalasa
            Page.grid = $target.isotope(settings);
            Page.grid.on('layoutComplete', function(e, laidOutItems){
                // Nem latszodo projektek elrejtese animalasra
                $('.project').each(function(){
                    if(!$(this).visible()){
                        $(this).removeClass('no-slide-in slide-in');
                    }
                });

                // Slide-in effekt
                Page.projectSlideIn();
            });

            // Isotope filter
            $('#filters').on('click', '.filter', function(e){
                e.preventDefault();
                // Allapotvaltas
                $(this).toggleClass('selected');
                // Projektek szurese
                Page.filterProjects();
            });
        },

        // Slide-in effekt
        projectSlideIn: function(){
            $('.project:not(.no-slide-in)').each(function(){
                if($(this).visible(true)) $(this).addClass('slide-in');
            });
        },

        // Projektek szurese
        filterProjects: function(){
            var filter = '',
                categories = [],
                labels = [],
                filters = [],
                $filters = $('#filters');

            // Kategoriak
            $('.menu[data-filter-group="categories"] .filter.selected[data-filter]', $filters).each(function(){
                categories.push($(this).data('filter'));
            });
            // Cimkek
            $('.menu[data-filter-group="labels"] .filter.selected[data-filter]', $filters).each(function(){
                labels.push($(this).data('filter'));
            });

            // Szuro osszerakasa
            if(!categories.length){
                filter = labels.join(',');
            } else if(!labels.length){
                filter = categories.join(',');
            } else {
                for(var i in categories){
                    for(var j in labels){
                        filters.push(categories[i] + labels[j]);
                    }
                }
                filter = filters.join(',');
            }
            // Ha nincs szuro, akkor mindent
            if(!filter) filter = '*';

            // Szures
            Page.grid.isotope({ filter: filter });
            debug.log(categories, labels, filter);
        },

        // lightGallery betoltese
        setGallery: function($target){
            // Slideshow elemeinek linkjei
            var urls = $target.data('slideshow'),
                dynamicEl = [];

            // Parameterek
            $.each(urls, function(i, item){
                dynamicEl.push({ src: item });
            });
            var params = {
                dynamic: true,
                dynamicEl: dynamicEl
            }
            var settings = $.extend(true, {}, Page.lightGallery, params);

            // Megjelenitese
            $target.lightGallery(settings);
        },

//------------------------------------------------------------------------------------------------------------------------
    // (5) Tartalmak

        // Tartalmak betoltese
        openContent: function($target){
            // Ha nincs hozza tartalom rendelve
            if(!$target.data('section')){
                return false;
            }

            // Ha letezik a tartalom
            var $section = $('section#' + $target.data('section'));
            if($section.length){
                // Filter eseten csak a menut csukjuk be (+ animacio vegen projectSlideIn)
                if($target.data('section') === 'filters'){
                    $('#menu').slideUp(500, Page.projectSlideIn());
                }
                // Minden mas eseten minden lathato tartalmat (+ animacio vegen projectSlideIn)
                else {
                    $('section:visible').slideUp(500, Page.projectSlideIn());
                }
                // Ha meg nem volt lathato a kinyitando tartalom, akkor kinyitjuk
                if(!$section.is(':visible')){
                    $section.slideDown(500);
                }
            }
        },

        // Tartalmak bezarasa
        closeContent: function($target){
            // Ha letezik a tartalom
            var $section = $target.closest('section:visible');
            if($section.length){
                // Tartalom bezarasa
                $section.slideUp(500, function(){
                    // Animacio vegen projectSlideIn
                    Page.projectSlideIn();
                });
            }
        },

//------------------------------------------------------------------------------------------------------------------------
    // (6) SVG betoltese es fallback

        // SVG betoltese es fallback
        loadSVG: function(){
            // SVG-PNG csere, ha nincs inline SVG tamogatas
            if(!Modernizr.inlinesvg){
                $('.icon.svg').each(function(){
                    var $icon = $(this).empty();

                    // Fallback
                    if($icon.data('icon')){
                        $icon.css('backgroundImage', "url('" + $('body').data('icon') + 'images/' + $icon.data('icon') + ".png')");
                    }
                    $icon.removeClass('svg');

                    // Ha linket szeretnenk
                    if($icon.data('href')){
                        var $a = $('<a href="' + $icon.data('href') + '" />');
                        if($icon.data('target')){
                            $a.attr('target', $icon.data('target'));
                        }
                        if($icon.data('title')){
                            $a.html('<span class="hidden">' + $icon.data('title') + '</span>').attr('title', $icon.data('title'));
                        }
                        $icon.prepend($a);
                    }
                });
            // SVG keszites
            } else {
                $('.icon.svg').each(function(){
                    var $icon = $(this).empty();

                    // Ha nincs ikon
                    if(!$icon.data('icon')){
                        $icon.removeClass('svg');
                    }
                    // SVG fajl betoltese
                    else {
                        $.get($('body').data('icon') + 'images/' + $icon.data('icon') + '.svg', function(data){
                            // SVG objektum letrehozasa a megfelelo attributumokkal
                            var $svg = $(data).find('svg').removeAttr('xmlns:a');
                            $icon.append($svg);

                            // Ha linket szeretnenk
                            if($icon.data('href')){
                                var $a = $('<a href="' + $icon.data('href') + '" />');
                                if($icon.data('target')){
                                    $a.attr('target', $icon.data('target'));
                                }
                                if($icon.data('title')){
                                    $a.html('<span class="hidden">' + $icon.data('title') + '</span>').attr('title', $icon.data('title'));
                                }
                                $icon.prepend($a);
                            }
                        }, 'xml');
                    }
                });
            }
        }

//------------------------------------------------------------------------------------------------------------------------
    };
}(jQuery));
